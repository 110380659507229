import axiosInstance, { axiosMediaApi } from "@/services/instanceService";

export default {
  state: {
    videoFileUrl: "",
    photoUrl: "",
    thumbnailUrl: "",
    activeVideoId: "",
    activePhotoId: "",
    storedTextContent: "",
  },
  mutations: {
    setVideoFileUrl(state, payload) {
      state.videoFileUrl = payload;
    },
    setPhotoUrl(state, payload) {
      state.photoUrl = payload;
    },
    setThumbnailUrl(state, payload) {
      state.thumbnailUrl = payload;
    },
    setActiveVideoId(state, payload) {
      state.activeVideoId = payload;
    },
    setActivePhotoId(state, payload) {
      state.activePhotoId = payload;
    },
    setStoredTextContent(state, payload) {
      state.storedTextContent = payload;
    },
  },
  actions: {
    loadVideoUrl({ commit }, data) {
      return axiosInstance({
        method: "POST",
        url: `media/loadVideoUrl/`,
        data,
      })
        .then((response) => {
          commit("setVideoFileUrl", response.data.videoFileUrl);
          commit("setThumbnailUrl", response.data.thumbnailUrl);
          let payload = {
            _id: data._id,
            content: response.data.videoFileUrl,
          };
          this.commit("report/saveReportContent", payload);
          return;
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
    loadPhotoUrl({ commit }, data) {
      return axiosInstance({
        method: "POST",
        url: `media/loadPhotoUrl/`,
        data,
      })
        .then((response) => {
          commit("setPhotoUrl", response.data.photoUrl);
          let payload = {
            _id: data._id,
            content: response.data.photoUrl,
          };
          this.commit("report/saveReportContent", payload);
          return;
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
    loadTextContent({ commit }, data) {
      return axiosInstance({
        method: "POST",
        url: `media/loadTextContent/`,
        data,
      })
        .then((response) => {
          commit("setStoredTextContent", response.data.contentText);
          let payload = {
            _id: data._id,
            content: response.data.contentText,
          };
          this.commit("report/saveReportContent", payload);
          return;
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },

    setMediaStatus({ dispatch }, data) {
      return axiosInstance({
        method: "POST",
        url: "media/setMediaStatus/",
        data,
      })
        .then((response) => {
          let thumbnail = response.data.thumbnail;
          let content = `${process.env.VUE_APP_STORAGE}${data.ownerUserId}/thumbMd/${thumbnail}`;
          const payload = {
            _id: data._id,
            adminHandler: data.adminHandler,
            adminRemarks: data.adminRemarks,
            contentLevel: data.contentLevel,
            handled: 4,
            ownerUserId: data.ownerUserId,
            remarksTime: data.remarksTime,
            content,
            type: data.contentType,
            finished: false,
            adminRemarksCount: data.adminRemarksCount + 1,
          };
          if (data.status == 0) {
            dispatch("report/handleReport", payload);
            this.commit("report/setHandledReportCase", payload);
          } else if (data.status == 1) {
            const payload = {
              _id: data._id,
              adminHandler: data.adminHandler,
              adminRemarks: data.adminRemarks,
              remarksTime: data.remarksTime,
              contentLevel: data.contentLevel,
              handled: 2,
              ownerUserId: data.ownerUserId,
              finished: true,
              adminRemarksCount: data.adminRemarksCount + 1,
            };
            dispatch("report/handleReport", payload);
            this.commit("report/modifyFinishedReport", payload);
          }
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          console.log(error);
          return error;
        });
    },
    setTextContentStatus({ dispatch }, data) {
      return axiosInstance({
        method: "POST",
        url: "media/setTextContentStatus/",
        data,
      })
        .then((response) => {
          if (data.status == 0) {
            const payload = {
              _id: data._id,
              adminHandler: data.adminHandler,
              adminRemarks: data.adminRemarks,
              contentLevel: data.contentLevel,
              handled: 4,
              ownerUserId: data.ownerUserId,
              remarksTime: data.remarksTime,
              finished: false,
              type: data.contentType,
              content: response.data.contentText,
              adminRemarksCount: data.adminRemarksCount + 1,
            };
            dispatch("report/handleReport", payload);
            this.commit("report/setHandledReportCase", payload);
          } else if (data.status == 1) {
            const payload = {
              _id: data._id,
              adminHandler: data.adminHandler,
              adminRemarks: data.adminRemarks,
              contentLevel: data.contentLevel,
              handled: 2,
              ownerUserId: data.ownerUserId,
              remarksTime: data.remarksTime,
              finished: true,
              adminRemarksCount: data.adminRemarksCount + 1,
            };
            dispatch("report/handleReport", payload);
            this.commit("report/modifyFinishedReport", payload);
          }
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          console.log(error);
          return error;
        });
    },

    deletePhotoData({ dispatch }, data) {
      return axiosMediaApi({
        method: "POST",
        url: "photo/deletePhotoData/",
        data,
      })
        .then(() => {
          const payload = {
            _id: data._id,
            adminHandler: data.adminHandler,
            adminRemarks: data.adminRemarks,
            contentLevel: data.contentLevel,
            handled: 3,
            ownerUserId: data.ownerUserId,
            remarksTime: data.remarksTime,
            finished: true,
          };
          dispatch("report/handleReport", payload);
          this.commit("report/modifyFinishedReport", payload);
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          return error;
        });
    },
    deleteVideoData({ dispatch }, data) {
      return axiosMediaApi({
        method: "POST",
        url: "video/deleteVideoData/",
        data,
      })
        .then(() => {
          const payload = {
            _id: data._id,
            adminHandler: data.adminHandler,
            adminRemarks: data.adminRemarks,
            contentLevel: data.contentLevel,
            handled: 3,
            ownerUserId: data.ownerUserId,
            remarksTime: data.remarksTime,
            finished: true,
          };
          dispatch("report/handleReport", payload);
          this.commit("report/modifyFinishedReport", payload);
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          return error;
        });
    },
    deleteTextContent({ dispatch }, data) {
      return axiosInstance({
        method: "POST",
        url: "media/deleteTextContent/",
        data,
      })
        .then(() => {
          const payload = {
            _id: data._id,
            adminHandler: data.adminHandler,
            adminRemarks: data.adminRemarks,
            contentLevel: data.contentLevel,
            handled: 3,
            ownerUserId: data.ownerUserId,
            remarksTime: data.remarksTime,
            finished: true,
          };
          dispatch("report/handleReport", payload);
          this.commit("report/modifyFinishedReport", payload);
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
  },
};
