import axiosInstance, { axiosDefault } from "@/services/instanceService";

export default {
  state: {
    maintenance: {},
    maintenanceTemplate: {
      _id: "",
      status: 0,
      maintenanceTime: "",
      notes: "",
      downtime: {
        hours: 0,
        minutes: 0,
      },
    },
  },
  mutations: {
    setIsServerMaintenance(state, payload) {
      if (!payload)
        return (state.maintenance = { ...state.maintenanceTemplate });
      payload.status == 1
        ? (state.maintenance = payload)
        : (state.maintenance = { ...state.maintenanceTemplate });
    },
  },
  actions: {
    loadServerMaintenanceStatus({ commit }) {
      return axiosDefault({
        method: "POST",
        url: `maintenance/loadServerMaintenanceStatus/`,
      })
        .then((data) => {
          commit("setIsServerMaintenance", data.data.data);
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
    createMaintenanceSchedule(context, data) {
      return axiosInstance({
        method: "POST",
        url: `maintenance/createMaintenanceSchedule/`,
        data,
      });
    },
    deleteMaintenanceSchedule({ commit }, data) {
      return axiosInstance({
        method: "POST",
        url: `maintenance/deleteMaintenanceSchedule/`,
        data,
      })
        .then(() => {
          commit("setIsServerMaintenance", { status: 0 });
          return;
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
  },
};
