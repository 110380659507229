import axios from "axios";
const API_URL = process.env.VUE_APP_BASE_API;
const API_STORAGE = process.env.VUE_APP_STORAGE_API;
const axiosInstance = axios.create({
  baseURL: API_URL,
});
const axiosMedia = axios.create({
  baseURL: API_STORAGE,
});
axiosInstance.interceptors.request.use(
  function (config) {
    var token = JSON.parse(localStorage.getItem("adminToken"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
axiosMedia.interceptors.request.use(
  function (config) {
    var token = JSON.parse(localStorage.getItem("adminToken"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export default axiosInstance;
export const axiosMediaApi = axiosMedia;
export const axiosDefault = axios.create({
  baseURL: API_URL,
});
