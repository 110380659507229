<template>
  <div class="home">
    <div id="nav">
      <router-link :to="'/maintenance'">Maintenance page</router-link> |
      <router-link :to="'/admin'">Admin page</router-link>|
      <router-link :to="'/report'">Report page</router-link>
      <!-- | -->
      <!-- <router-link to="/ticket">Ticket page</router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
