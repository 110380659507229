import axiosInstance from "@/services/instanceService";

export default {
  namespaced: true,
  state: {
    // store state for reportedUser
    unhandledReportedUser: [],
    finishedReportedUser: [],
    searchedUser: {},
    keyword: "",

    noMoreUnhandledReportedUser: false,
    noMoreFinishedReportedUser: false,
    noMoreSearchedUser: {},

    // store state for reportedCase list
    unhandledReportedCase: {},
    finishedReportedCase: {},
    noMoreUnhandledReportedCase: {},
    noMoreFinishedReportedCase: {},

    // store state for reportedCase detail
    reportedCaseDetail: {},
    isReportedCaseHandler: false,

    // store state for reports
    reports: {},
    noMoreReports: {},

    loadMoreReportIsLoading: false,
    loadMoreReportedUserIsLoading: false,
  },
  mutations: {
    saveReportContent(state, payload) {
      // video: videoUrl, photo: photoThumbnail, post: text content
      let { _id, content } = payload;
      if (!state.reportedCaseDetail[_id].content) {
        state.reportedCaseDetail[_id].content = "";
      }
      state.reportedCaseDetail[_id].content = content;
      state.reportedCaseDetail = { ...state.reportedCaseDetail };
    },

    setReport(state, payload) {
      let contentId = payload.contentId;
      if (!state.reports[contentId]) {
        state.reports[contentId] = [];
      }
      state.reports[contentId] = [
        ...state.reports[contentId],
        ...payload.reports,
      ];
      state.noMoreReports[contentId] = payload.noMoreData;
      state.noMoreReports = { ...state.noMoreReports };
      state.reports = { ...state.reports };
    },
    setReportedUser(state, payload) {
      if (state.keyword != "") {
        state.searchedUser[state.keyword] = [
          ...state.searchedUser[state.keyword],
          ...payload.reportedUser,
        ];
        state.searchedUser = { ...state.searchedUser };
        state.noMoreSearchedUser[state.keyword] = payload.noMoreData;
      } else {
        if (payload.path == "UnhandledReportedUser") {
          if (!state.unhandledReportedUser) {
            state.unhandledReportedUser = [];
          }
          state.unhandledReportedUser = [
            ...state.unhandledReportedUser,
            ...payload.reportedUser,
          ];
          state.noMoreUnhandledReportedUser = payload.noMoreData;
          state.unhandledReportedUser = [...state.unhandledReportedUser];
        } else if (payload.path == "FinishedReportedUser") {
          if (!state.finishedReportedUser) {
            state.finishedReportedUser = [];
          }
          state.finishedReportedUser = [
            ...state.finishedReportedUser,
            ...payload.reportedUser,
          ];
          state.noMoreFinishedReportedUser = payload.noMoreData;
          state.finishedReportedUser = [...state.finishedReportedUser];
        }
      }
    },
    setReportedCase(state, payload) {
      // if (state.keyword != "") {
      //   state.searchedUser[state.keyword] = [
      //     ...state.searchedUser[state.keyword],
      //     ...payload.reportedCase,
      //   ];
      //   state.searchedUser = { ...state.searchedUser };
      //   state.noMoreSearchedUser[state.keyword] = payload.noMoreData;
      // } else {
      // console.log(payload);
      if (payload.path == "UnhandledReportedCase") {
        if (!state.unhandledReportedCase[payload.reportedId]) {
          state.unhandledReportedCase[payload.reportedId] = [];
          state.noMoreUnhandledReportedCase[payload.reportedId] = false;
        }
        state.unhandledReportedCase[payload.reportedId] = [
          ...state.unhandledReportedCase[payload.reportedId],
          ...payload.reportedCase,
        ];
        state.noMoreUnhandledReportedCase[payload.reportedId] =
          payload.noMoreData;

        state.noMoreUnhandledReportedCase = {
          ...state.noMoreUnhandledReportedCase,
        };
        state.unhandledReportedCase = { ...state.unhandledReportedCase };
      } else if (payload.path == "FinishedReportedCase") {
        if (!state.finishedReportedCase[payload.reportedId]) {
          state.finishedReportedCase[payload.reportedId] = [];
          state.noMoreFinishedReportedCase[payload.reportedId] = false;
        }
        state.finishedReportedCase[payload.reportedId] = [
          ...state.finishedReportedCase[payload.reportedId],
          ...payload.reportedCase,
        ];
        state.noMoreFinishedReportedCase[payload.reportedId] =
          payload.noMoreData;

        state.noMoreFinishedReportedCase = {
          ...state.noMoreFinishedReportedCase,
        };
        state.finishedReportedCase = { ...state.finishedReportedCase };
      }
      // }
    },
    setReportedCaseDetail(state, payload) {
      state.reportedCaseDetail[payload._id] = payload.reportedCase;
      state.reportedCaseDetail = { ...state.reportedCaseDetail };
    },

    setIsReportedCaseHandler(state, payload) {
      const isAdminHandler = payload.reportedCase.adminHandler.find(
        (e) => e.adminId == payload.currentAdminId
      );
      state.isReportedCaseHandler = isAdminHandler ? true : false;
    },

    setHandledReportCase(state, payload) {
      state.reportedCaseDetail[payload._id].handled = payload.handled;
      if (!state.reportedCaseDetail[payload._id].adminRemarks)
        state.reportedCaseDetail[payload._id].adminRemarks = [];
      if (!state.reportedCaseDetail[payload._id].adminHandler)
        state.reportedCaseDetail[payload._id].adminHandler = [];
      if (!state.reportedCaseDetail[payload._id].remarksTime)
        state.reportedCaseDetail[payload._id].remarksTime = [];

      state.reportedCaseDetail[payload._id].adminRemarks = [
        ...state.reportedCaseDetail[payload._id].adminRemarks,
        ...[payload.adminRemarks],
      ];
      state.reportedCaseDetail[payload._id].adminHandler = [
        ...state.reportedCaseDetail[payload._id].adminHandler,
        ...[payload.adminHandler],
      ];
      state.reportedCaseDetail[payload._id].remarksTime = [
        ...state.reportedCaseDetail[payload._id].remarksTime,
        ...[payload.remarksTime.toISOString()],
      ];

      state.reportedCaseDetail[payload._id].adminRemarksCount =
        payload.adminRemarksCount;

      if (!state.reportedCaseDetail[payload._id].finishedCount)
        state.reportedCaseDetail[payload._id].finishedCount = 0;

      state.reportedCaseDetail[payload._id].finishedCount += 1;

      if (state.reportedCaseDetail[payload._id].unhandledCount != 0)
        state.reportedCaseDetail[payload._id].unhandledCount -= 1;

      state.reportedCaseDetail = { ...state.reportedCaseDetail };
    },
    modifyFinishedReport(state, payload) {
      state.reportedCaseDetail[payload._id].handled = payload.handled;
      state.reportedCaseDetail[payload._id].adminRemarksCount =
        payload.adminRemarksCount;
      state.reportedCaseDetail = { ...state.reportedCaseDetail };
    },
    setAccountStatus(state, payload) {
      state.reportedCaseDetail[payload.caseId].account_status =
        payload.account_status;
      state.reportedCaseDetail = { ...state.reportedCaseDetail };
    },
    setAdminRemarks(state, payload) {
      state.reportedCaseDetail[payload._id].adminHandler = [
        ...state.reportedCaseDetail[payload._id].adminHandler,
        ...[payload.adminHandler],
      ];
      state.reportedCaseDetail[payload._id].adminRemarks = [
        ...state.reportedCaseDetail[payload._id].adminRemarks,
        ...[payload.adminRemarks],
      ];
      state.reportedCaseDetail[payload._id].remarksTime = [
        ...state.reportedCaseDetail[payload._id].remarksTime,
        ...[payload.remarksTime.toISOString()],
      ];
      state.reportedCaseDetail = { ...state.reportedCaseDetail };
    },

    setKeyword(state, payload) {
      state.keyword = payload;
    },
    resetSearchByUsername(state) {
      state.searchedUser = {};
      state.keyword = "";
      state.noMoreSearchedUser = {};
    },

    setLoadMoreReportIsLoading(state, payload) {
      state.loadMoreReportIsLoading = payload;
    },
    setLoadMoreReportedUserIsLoading(state, payload) {
      state.loadMoreReportedUserIsLoading = payload;
    },
    setInitialDataReport(state) {
      // store state for reportedUser
      state.unhandledReportedUser = [];
      state.finishedReportedUser = [];
      state.searchedUser = {};
      state.keyword = "";
      state.noMoreUnhandledReportedUser = false;
      state.noMoreFinishedReportedUser = false;
      state.noMoreSearchedUser = {};
      // store state for reportedCase list
      state.unhandledReportedCase = {};
      state.finishedReportedCase = {};
      state.noMoreUnhandledReportedCase = {};
      state.noMoreFinishedReportedCase = {};
      // store state for reportedCase detail
      state.reportedCaseDetail = {};
      state.isReportedCaseHandler = false;
      // store state for reports
      state.reports = {};
      state.noMoreReports = {};
      state.loadMoreReportIsLoading = false;
      state.loadMoreReportedUserIsLoading = false;
    },
  },
  actions: {
    // Reported USER
    loadReportedUser({ state }, data) {
      if (state.keyword != "" && state.noMoreSearchedUser[data.keyword]) {
        this.commit("report/setKeyword", data.keyword);
        return;
      } else if (state.keyword != "" && data.keyword == "") {
        state.keyword = "";
        return;
      }

      if (!state.searchedUser[data.keyword] && data.keyword != "") {
        state.searchedUser[data.keyword] = [];
        state.noMoreSearchedUser[data.keyword] = false;
      }
      return axiosInstance({
        method: "POST",
        url: `report/load${data.path}/`,
        data,
      })
        .then((response) => {
          const payload = {
            reportedUser: response.data.reportedUser,
            noMoreData: response.data.noMoreData,
            path: data.path,
          };
          this.commit("report/setKeyword", data.keyword);
          this.commit("report/setReportedUser", payload);
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
    // Reported CASE
    loadReportedCase(context, data) {
      return axiosInstance({
        method: "POST",
        url: `report/load${data.path}/`,
        data,
      })
        .then((response) => {
          const payload = {
            reportedCase: response.data.reportedCase,
            noMoreData: response.data.noMoreData,
            path: data.path,
            reportedId: data.reportedId,
          };
          this.commit("report/setReportedCase", payload);
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
    // Reports
    loadCaseReports(context, data) {
      return axiosInstance({
        method: "POST",
        url: `report/loadCaseReports/`,
        data,
      })
        .then((response) => {
          const payload = {
            reports: response.data.reports,
            noMoreData: response.data.noMoreData,
            contentId: data.contentId,
          };
          this.commit("report/setReport", payload);
        })
        .catch((error) => {
          return error;
        });
    },
    // Reported CASE DETAIL
    loadReportedCaseDetail({ state }, data) {
      if (!state.reportedCaseDetail[data._id]) {
        return axiosInstance({
          method: "POST",
          url: `report/loadReportedCaseDetail/`,
          data,
        })
          .then((response) => {
            const payload = {
              reportedCase: response.data.reportedCase,
              _id: data._id,
              currentAdminId: data.currentAdminId,
            };
            this.commit("report/setReportedCaseDetail", payload);
            this.commit("report/setIsReportedCaseHandler", payload);
          })
          .catch((error) => {
            // console.log(error);
            return error;
          });
      } else {
        const payload = {
          reportedCase: state.reportedCaseDetail[data._id],
          _id: data._id,
          currentAdminId: data.currentAdminId,
        };
        this.commit("report/setIsReportedCaseHandler", payload);
        return;
      }
    },

    handleReport(context, data) {
      return axiosInstance({
        method: "POST",
        url: "report/handleReport/",
        data,
      });
    },

    editAdminRemarks(context, data) {
      return axiosInstance({
        method: "POST",
        url: "report/editAdminRemarks/",
        data,
      });
    },

    banUser(context, data) {
      return axiosInstance({
        method: "POST",
        url: `report/banUser/`,
        data,
      })
        .then((response) => {
          this.commit("report/setAccountStatus", data);
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    unbanUser(context, data) {
      return axiosInstance({
        method: "POST",
        url: `report/unbanUser/`,
        data,
      })
        .then((response) => {
          this.commit("report/setAccountStatus", {
            _id: data._id,
            account_status: 1,
            caseId: data.caseId,
          });
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
