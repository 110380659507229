import axiosInstance, { axiosDefault } from "@/services/instanceService";

export default {
  state: {
    admin: {},
    loggedIn: false,
    admins: [],
  },
  mutations: {
    setAdminData(state, payload) {
      state.admin = payload;
    },
    setLoginStatus(state, payload) {
      state.loggedIn = payload;
    },
    setAdmins(state, payload) {
      state.admins = payload;
    },
    removeAdminFromAdmins(state, payload) {
      const adminIndex = state.admins.findIndex(
        (admin) => admin._id === payload
      );
      if (adminIndex > -1) {
        state.admins.splice(adminIndex, 1);
      }
      state.admins = [...state.admins];
    },
    deactiveAdminFromAdmins(state, payload) {
      const adminIndex = state.admins.findIndex(
        (admin) => admin._id === payload
      );
      if (adminIndex > -1) {
        state.admins[adminIndex].active = 0;
      }
      state.admins = [...state.admins];
    },
    activeAdminFromAdmins(state, payload) {
      const adminIndex = state.admins.findIndex(
        (admin) => admin._id === payload
      );
      if (adminIndex > -1) {
        state.admins[adminIndex].active = 1;
      }
      state.admins = [...state.admins];
    },
    setAdminResetPass(state, payload) {
      const adminIndex = state.admins.findIndex(
        (admin) => admin._id === payload
      );
      if (adminIndex > -1) {
        state.admins[adminIndex].resetPassword = 0;
      }
      state.admins = [...state.admins];
    },
    setAdminLevelAfterPromote(state, payload) {
      const adminIndex = state.admins.findIndex((el) => el._id === payload);
      if (adminIndex > -1) {
        state.admins[adminIndex].level += 1;
      }
      state.admins = [...state.admins];
    },
    setAdminLevelAfterDemote(state, payload) {
      const adminIndex = state.admins.findIndex((el) => el._id === payload);
      if (adminIndex > -1) {
        state.admins[adminIndex].level -= 1;
      }
      state.admins = [...state.admins];
    },
  },
  actions: {
    adminLogin(context, data) {
      return axiosDefault({
        method: "POST",
        url: `admin/adminLogin/`,
        data,
      }).then((data) => {
        localStorage.setItem("adminToken", JSON.stringify(data.data.token));
        localStorage.setItem("adminData", JSON.stringify(data.data.admin));
        this.commit("setLoginStatus", true);
        this.commit("setAdminData", data.data.admin);
      });
    },
    adminLogout() {
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminData");
      this.commit("setLoginStatus", false);
      this.commit("setAdminData", {});
      this.commit("setAdmins", []);
      return;
    },
    createAdmin(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/createAdmin/`,
        data,
      });
    },
    deleteAdmin(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/deleteAdmin/`,
        data,
      }).then(() => {
        this.commit("removeAdminFromAdmins", data._id);
      });
    },
    deactiveAdmin(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/deactiveAdmin/`,
        data,
      }).then(() => {
        this.commit("deactiveAdminFromAdmins", data._id);
      });
    },
    activeAdmin(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/activeAdmin/`,
        data,
      }).then(() => {
        this.commit("activeAdminFromAdmins", data._id);
      });
    },
    promoteAdmin(context, data) {
      return axiosInstance({
        method: "POST",
        url: "admin/promoteAdmin/",
        data,
      }).then(() => {
        this.commit("setAdminLevelAfterPromote", data._id);
      });
    },
    demoteAdmin(context, data) {
      return axiosInstance({
        method: "POST",
        url: "admin/demoteAdmin/",
        data,
      }).then(() => {
        this.commit("setAdminLevelAfterDemote", data._id);
      });
    },
    loadAdmins(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/loadAdmins/`,
        data,
      }).then((response) => {
        this.commit("setAdmins", response.data.admins);
      });
    },
    deleteUser(context, data) {
      return axiosInstance({
        method: "POST",
        url: `user/deleteAllFlaggedAccount/`,
        data,
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    deleteOldNotif(context, data) {
      return axiosInstance({
        method: "POST",
        url: `notification/deleteOldNotif/`,
        data,
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    updateAdminPassword(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/updateAdminPassword/`,
        data,
      }).then(() => {
        this.commit("setAdminResetPass", data.adminId);
      });
    },
    forgotPassword(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/forgotPassword/`,
        data,
      });
    },
    ownerForgotPassword(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/ownerForgotPassword/`,
        data,
      });
    },
    changeOwnerPassword(context, data) {
      return axiosInstance({
        method: "POST",
        url: `admin/changeOwnerPassword/`,
        data,
      });
    },
  },
};
