<template>
  <div class="header">
    <b-container fluid class="iq-navbar-custom fixed-top">
      <div class="row justify-content-between">
        <!-- LOGO -->
        <b-col class="col-header-logo" lg="4" md="4" sm="4">
          <b-navbar-brand class="iq-navbar-logo d-flex justify-content-between">
            <b-link @click="loadHome">
              <b-img src="/images/mindcaster_logo.png" />
            </b-link>
          </b-navbar-brand>
        </b-col>
        <!-- LOGO -->

        <b-col lg="4" md="4" sm="4"> </b-col>

        <!-- RightSidebar -->
        <b-col lg="4" md="4" sm="4">
          <h5>
            {{ adminName }}
          </h5>
          <b-button v-if="loggedIn" variant="primary" pill @click="adminLogout">
            Logout
          </b-button>
        </b-col>
        <!-- RightSidebar -->
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapState({
      loggedIn: (state) => state.admin.loggedIn,
      adminName: (state) => state.admin.admin.name,
    }),
  },
  async created() {},
  mounted() {
    const token = localStorage.getItem("adminToken");
    if (token) {
      this.$store.commit("setLoginStatus", true);
      const admin = JSON.parse(localStorage.getItem("adminData"));
      this.$store.commit("setAdminData", admin);
    } else {
      this.$store.commit("setLoginStatus", false);
    }
  },
  destroyed() {},
  methods: {
    loadHome() {
      this.$router.push({ name: "Home" });
    },
    adminLogout() {
      this.$store.dispatch("adminLogout").then(() => {
        this.$store.commit("report/setInitialDataReport");
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>
