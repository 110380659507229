<template>
  <div class="iq-sidebar">
    <div id="sidebar-scrollbar">
      <b-card>
        <b-list-group class="leftSidebarMenu pl-0">
          <b-list-group-item>
            <div class="sidebar-parent" @click="handleRouting('Home')">
              Home
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="sidebar-parent" @click="handleRouting('Maintenance')">
              Maintenance
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="sidebar-parent" v-b-toggle.collapse-admin>Admin</div>
            <b-collapse id="collapse-admin" class="mt-2">
              <b-card
                class="sidebar-child"
                @click="handleRouting('Create Admin')"
              >
                Create
              </b-card>
              <b-card
                class="sidebar-child"
                @click="handleRouting('Admin List')"
              >
                List
              </b-card>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <div class="sidebar-parent" v-b-toggle.collapse-report>Report</div>
            <b-collapse id="collapse-report" class="mt-2">
              <b-card
                class="sidebar-child"
                @click="handleRouting('Unhandled Report')"
              >
                Unhandled Case: Waiting List
              </b-card>
              <b-card
                class="sidebar-child"
                @click="handleRouting('Finished Report')"
              >
                Handled Case: On-going / Finished
              </b-card>
            </b-collapse>
          </b-list-group-item>

          <!-- change admin level for admin authority -->
          <b-list-group-item v-if="admin.level >= adminMinLevel">
            <div class="sidebar-parent" @click="handleRouting('Admin Tool')">
              Admin Tools
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SideBarStyle1",
  components: {},
  data() {
    return {
      adminMinLevel: process.env.VUE_APP_MIN_LEVEL_FOR_DELETE_ACCOUNT,
    };
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin,
    }),
  },
  methods: {
    handleRouting(name) {
      if (this.$route.name != name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>
