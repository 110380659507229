import axiosInstance from "@/services/instanceService";

export default {
  state: {
    unhandledTickets: [],
    activeTickets: [],
    finishedTickets: [],
  },
  mutations: {
    setTicket(state, payload) {
      if (payload.path == "UnhandledTickets") {
        state.unhandledTickets = [
          ...state.unhandledTickets,
          ...payload.tickets,
        ];
      } else if (payload.path == "ActiveTickets") {
        state.activeTickets = [...state.activeTickets, ...payload.tickets];
      } else if (payload.path == "FinishedTickets") {
        state.finishedTickets = [...state.finishedTickets, ...payload.tickets];
      }
    },
    clearTicketState(state, path) {
      if (path == "UnhandledTickets") {
        state.unhandledTickets = [];
      } else if (path == "ActiveTickets") {
        state.activeTickets = [];
      } else if (path == "FinishedTickets") {
        state.finishedTickets = [];
      }
    },
  },
  actions: {
    loadTickets(context, data) {
      return axiosInstance({
        method: "POST",
        url: `ticket/load${data.path}/`,
        data,
      })
        .then((response) => {
          const payload = {
            tickets: response.data.tickets,
            path: response.data.path,
          };
          this.commit("setTicket", payload);
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
  },
};
