<template>
  <div id="app">
    <Header />
    <LeftSidebar v-if="loggedIn" />
    <router-view :class="loggedIn ? 'page-content' : 'login-page'" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Header from "@/components/navigation/Header.vue";
import LeftSidebar from "@/components/navigation/LeftSidebar.vue";

export default {
  name: "App",
  components: {
    Header,
    LeftSidebar,
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.admin.loggedIn,
    }),
  },
};
</script>

<style></style>
