import Vue from "vue";
import Vuex from "vuex";

import admin from "./admin";
import maintenance from "./maintenance";
import media from "./media";
import ticket from "./ticket";
import report from "./report";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    admin,
    maintenance,
    media,
    ticket,
    report,
  },
});
