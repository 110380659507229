import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../views/MaintenancePage.vue"),
  },
  {
    path: "/ownerResetPage",
    name: "Owner Reset Page",
    component: () => import("../views/OwnerResetPage.vue"),
  },
  {
    path: "/adminTool",
    name: "Admin Tool",
    component: () => import("../views/AdminToolPage.vue"),
  },
  {
    path: "/admin",
    component: () => import("../views/AdminPage.vue"),
    children: [
      {
        path: "createAdmin",
        name: "Create Admin",
        component: () => import("../components/admin/FormAdmin.vue"),
      },
      {
        path: "",
        name: "Admin List",
        component: () => import("../components/admin/AdminContainer.vue"),
      },
    ],
  },
  {
    path: "/emailverification/:verificationId",
    name: "EmailVerification",
    component: () => import("../views/EmailVerification.vue"),
  },
  // {
  //   path: "/ticket",
  //   name: "Ticket",
  //   component: () => import("../views/TicketPage.vue"),
  // },
  {
    path: "/report",
    component: () => import("../views/ReportPage.vue"),
    children: [
      {
        path: "finished",
        name: "Finished Report",
        component: () =>
          import("../components/report/FinishedReportUserContainer.vue"),
      },
      {
        path: "/detail/:reportedContentId",
        name: "Reported Case Detail",
        component: () => import("../components/report/ReportedCaseDetail.vue"),
      },
      {
        path: "",
        name: "Unhandled Report",
        component: () =>
          import("../components/report/UnhandledReportUserContainer.vue"),
      },
    ],
  },
];

const router = new VueRouter({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("adminToken");
  const admin = JSON.parse(localStorage.getItem("adminData"));
  let adminLevel = 0;
  if (admin) adminLevel = admin.level;

  if (
    to.name == "Admin Tool" &&
    process.env.VUE_APP_MIN_LEVEL_FOR_DELETE_ACCOUNT > adminLevel
  ) {
    next({ name: "Home" });
  } else if (
    to.name != "Login" &&
    to.name != "Owner Reset Page" &&
    to.name != "EmailVerification" &&
    !token
  ) {
    next({ name: "Login" });
  } else if (
    (to.name == "Login" ||
      to.name == "Owner Reset Page" ||
      to.name == "EmailVerification") &&
    token
  ) {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
