import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import AppSocket from "./plugins/socket";

import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VCalendar from "v-calendar";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(AppSocket, {
  connection: process.env.VUE_APP_BACKEND_URL,
});

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
// Enable / Disable vue devtools on browser
// true: enable, false: disable
Vue.config.devtools = true;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
